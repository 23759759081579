exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-tudastar-tsx": () => import("./../../../src/pages/tudastar.tsx" /* webpackChunkName: "component---src-pages-tudastar-tsx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-6-eszkoz-amivel-hozzajarul-a-sharepoint-a-vallalati-hatekonysag-novelesehez-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/6-eszkoz-amivel-hozzajarul-a-sharepoint-a-vallalati-hatekonysag-novelesehez/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-6-eszkoz-amivel-hozzajarul-a-sharepoint-a-vallalati-hatekonysag-novelesehez-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-cel-url-kinyerese-a-dokumentumtarak-hivatkozasaibol-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/cel-url-kinyerese-a-dokumentumtarak-hivatkozasaibol/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-cel-url-kinyerese-a-dokumentumtarak-hivatkozasaibol-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-elonezeti-kepek-generalasa-sharepoint-dokumentumokhoz-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/elonezeti-kepek-generalasa-sharepoint-dokumentumokhoz/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-elonezeti-kepek-generalasa-sharepoint-dokumentumokhoz-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-fajlok-tomeges-letrehozasa-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/fajlok-tomeges-letrehozasa/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-fajlok-tomeges-letrehozasa-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-idopontfoglalo-utkozesek-vizsgalata-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/idopontfoglalo-utkozesek-vizsgalata/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-idopontfoglalo-utkozesek-vizsgalata-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-ingyenes-kepgyujtemenyek-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/ingyenes-kepgyujtemenyek/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-ingyenes-kepgyujtemenyek-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-sharepoint-csoporttagsag-ellenorzese-power-apps-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/sharepoint-csoporttagsag-ellenorzese-power-apps/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-sharepoint-csoporttagsag-ellenorzese-power-apps-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-spfx-fejlesztes-gyors-workbench-frissites-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/spfx-fejlesztes-gyors-workbench-frissites/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-spfx-fejlesztes-gyors-workbench-frissites-index-mdx" */),
  "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-windows-tisztitas-index-mdx": () => import("./../../../src/templates/post.tsx?__contentFilePath=C:/DEV/GreenTie2023/content/blog/windows-tisztitas/index.mdx" /* webpackChunkName: "component---src-templates-post-tsx-content-file-path-c-dev-green-tie-2023-content-blog-windows-tisztitas-index-mdx" */)
}

